import type { BrowserOptions, default as SentryType } from "@sentry/browser";

let Sentry: typeof SentryType | undefined;

export function loadSentry(config: BrowserOptions): typeof SentryType {
	// eslint-disable-next-line unicorn/prefer-module
	if (Sentry) {
		return Sentry;
	}

	// eslint-disable-next-line unicorn/prefer-module
	Sentry = require("@sentry/browser");
	Sentry?.init({
		attachStacktrace: true,
		tracesSampleRate: 0.5,
		denyUrls: [/^chrome:/, /^\w+-extension:/, /webkit-masked-url/, ...(config.denyUrls ?? [])],
		ignoreErrors: [
			/GoogleTranslate/,
			/dbankcloud/, // Huawei translator
			...(config.ignoreErrors ?? [])
		],
		beforeSend(event, hint) {
			// Handle webpack ChunkLoadError by reloading the page. We use a localStorage flag to avoid
			// an infinite reload loop in case something is wrong on the server.
			const error = hint?.originalException;
			if (error instanceof Error && error.name === "ChunkLoadError") {
				const lastReload = Number(localStorage["sentry-init-cold-reload"]) || 0;
				if (Date.now() - lastReload > 60_000) {
					localStorage["sentry-init-cold-reload"] = Date.now();
					window.location.reload();
					return null;
				}
			}
			return config.beforeSend ? config.beforeSend(event, hint) : event;
		},
		...config
	});
	return Sentry!;
}
