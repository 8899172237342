// Used by sentry-init.ts to check that the JS engine supports ES6 class and spread operator before
// initialising Sentry, so we don’t get useless errors reported by ancient browsers.
// Must be .js, not .ts, so the spread operator remains as-is.
// eslint-disable-next-line unicorn/no-static-only-class
class OutdatedBrowserTest {
	static test() {
		return (
			// Has spread operator
			// eslint-disable-next-line unicorn/no-useless-spread
			[...[true]][0] &&
			// Has promises
			window.Promise !== undefined &&
			// Has matchMedia
			window.matchMedia !== undefined &&
			// Not any version of IE
			!/; MSIE |; Trident/.test(navigator.userAgent)
		);
	}
}

const isSupportedBrowser = OutdatedBrowserTest.test();
export default isSupportedBrowser;
