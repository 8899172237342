import isSupportedBrowser from "./global/outdated-browser-test";
import { loadSentry } from "@chariz/components/utils/Sentry";

function sentryInit() {
	// Load Sentry only in production. This file is a no-op in dev.
	// Note: Don’t invert this logic so it returns early. When inverted, webpack doesn’t properly
	// no-op the code in this function for some reason.
	if (process.env.NODE_ENV === "production") {
		// Filter out browsers that are a lost cause and will just spam error reports.
		if (!isSupportedBrowser) {
			return;
		}

		const Sentry = loadSentry({
			dsn: process.env.FRONTEND_SENTRY_DSN,
			ignoreErrors: [
				// Useless video playback errors we don’t care about
				"NotAllowedError",
				"AbortError"
			],
			environment: process.env.NODE_ENV,
			release: process.env.RELEASE
		});

		const isCydia = navigator.userAgent.includes("Cydia");
		const isZebra = navigator.userAgent.includes("Zebra");
		const isReallyCydia = isCydia && !isZebra;
		if (isReallyCydia) {
			Sentry.setTag("client", "cydia");
		} else if (isZebra) {
			Sentry.setTag("client", "zebra");
		}
	}
}

if (document.documentElement.dataset.cookieConsent === "true") {
	sentryInit();
} else {
	window.sentryInit = sentryInit;
}
